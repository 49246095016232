import React, { useState} from 'react'
import {IntlProvider} from 'react-intl'
import Russian from '../../data/lang/ru.json'
import English from '../../data/lang/en.json'

export const Context = React.createContext()

const local = navigator.language.substring(0,2).toLowerCase()
let currentDictionary
if (local === 'ru') {
   currentDictionary = Russian
} else {
   currentDictionary = English
}
document.documentElement.lang = local
document.title = currentDictionary["main_title"]

const LanguageWrapper = (props) => {
   const [locale, setLocale] = useState(local)
   const [messages, setMessages] = useState(currentDictionary)

   function selectLanguage(e) {
      const newLocale = e.target.value

      setLocale(newLocale)
      if (newLocale === 'ru') {
         currentDictionary = Russian
      } else {
         currentDictionary = English
      }

      document.documentElement.lang = newLocale
      setMessages(currentDictionary)
      document.title = currentDictionary["main_title"]
   }

   return (
        <Context.Provider value = {{locale, selectLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
   )
}

export default LanguageWrapper