import React from 'react'
import Footer from '../containers/Footer'
import Banner from '../containers/Banner'
import WhoWeAre from '../containers/WhoWeAre'
import Projects from '../containers/Projects'
import WidgetTelegram from '../containers/WidgetTelegram'
import pageInfo from '../../data/page_info.json'
import Header from '../containers/Header'
import '../containers/Decoration.scss'

export default function LandingPage() {
  return (
    <div className="page landing-page">
      <Header />
      <Banner data={pageInfo.header} />
      <div className='main-content'>
        <div className={`wave-pattern-1 wave-pattern wave-pattern__1`}></div>
        <div className={`wave-pattern-2 wave-pattern wave-pattern__2`}></div>
        <div className={`wave-pattern-3 wave-pattern wave-pattern__3`}></div>
        <div className={`wave-pattern-4 wave-pattern wave-pattern__4`}></div>
        <WhoWeAre data={pageInfo.about} />
        <Projects data={pageInfo.projects} />
      </div>
      {/* <WidgetTelegram data={pageInfo.news} /> */}
      <Footer data={pageInfo.footer} />
    </div>
  )
}
