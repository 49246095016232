import React from 'react'
import './WhoWeAre.scss';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export default function WhoWeAre({data, ...props}) {
  const { title, desc } = data
  return (
    <div className="who-we-are">
      <Container>
        <div className="who-we-are__container">
          <div className="who-we-are__header">
            <h2>
              <FormattedMessage
                id = "about_title"
                defaultMessage={title}
              />
            </h2>
          </div>
        </div>
        <p>
          <FormattedMessage
            id = "about_text"
            defaultMessage={desc}
          />
        </p>
      </Container>

    </div>
  )
}
