import React, { useContext } from 'react'
import './Projects.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Context } from './LanguageWrapper';

const ProjectsItem = ({
  ref_id,
  id,
  title,
  img,
  desc,
  marker_list,
  desc_end,
  logo,
  link,
  button_text,
  button_link
}) => {

  const langContext = useContext(Context);

  return (
    <div id={ref_id} className={`projects-item ${img ? 'projects-item_with-image' : ''}`}>
      {img ? (
        <div className="projects-item__image-container">
          <img src={img} alt="" />
        </div>
      ) : (
        <div className='projects-item__content'>
          <Container>
            <Col className='projects-item__title-container'>
              <a href={link}>
                <h2>
                  <FormattedMessage
                    id={`project${id}_title`}
                    defaultMessage={title}
                  />
                </h2>
              </a>
              <div className='projects-item__logo-container'>
                <a href={link}>
                  <div className={`projects-item__logo ${langContext.locale === 'ru' ? logo : logo + '-en'}`}>&nbsp;</div>
                </a>
              </div>
            </Col>

          </Container>
          {marker_list ? (
            <>
              <p>
                <FormattedMessage
                  id={`project${id}_text`}
                  defaultMessage={desc}
                />
              </p>
              <ul>
                {marker_list.map((item, index) => (
                  <li key={index}>
                    <FormattedMessage
                      id={`project${id}_li_${index}`}
                      defaultMessage={item}
                    />
                  </li>))}
              </ul>
              <p>
                <FormattedMessage
                  id={`project${id}_text2`}
                  defaultMessage={desc_end}
                />
              </p>
            </>
          ) : (
            <p>
              <FormattedMessage
                id={`project${id}_text`}
                defaultMessage={desc}
              />
            </p>
          )}
          {button_text && (
            <a className="btn btn-primary" href={button_link} role="button">
              <FormattedMessage
                id={`project${id}_button_text`}
                defaultMessage={button_text}
              />
            </a>
          )}
        </div>
      )}
    </div>
  )
}

export default function Projects({ data, ...props }) {
  const { title, items } = data

  const hashRefs = []
  for (let index = 0; index < items.length; index++) {
    hashRefs[index] = items[index].link_title;
  }

  return (
    <div className="projects">
      <Container>
        <div className="projects__container">
          <div className="projects__header">
            <h2>
              <FormattedMessage
                id="projects_title"
                defaultMessage={title}
              />
            </h2>
            <nav>
              <ul>
                {items.map((item, index) => (
                  <li key={index}>
                    <a href={`#${hashRefs[index]}`}>
                      <FormattedMessage
                        id={`project${item.id}_link_title`}
                        defaultMessage={item.link_title}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
        <div className="projects__items-container">
          {items.map((item, index) => {
            return <ProjectsItem {...item} key={index} ref_id={`${hashRefs[index]}`} />
          })}
        </div>
      </Container>
    </div>
  )
}
