import React, { useContext } from 'react';
import './Banner.scss';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Context } from './LanguageWrapper'

export default function Banner({data, ...props}) {
  const { title1, title2 } = data

  const langContext = useContext(Context)

  return (
    <header className="banner header-image banner-bg">
      <Container>
        <div className="banner__top">
          <div className={`banner__logo-cms ${langContext.locale === 'ru' ? 'header-logo' : 'header-logo-en'}`} />
          {/* <div className="banner__left">
            <div className='banner__title'>
              <h1>
                <FormattedMessage
                  id = "title1"
                  defaultMessage={title1}
                />
              </h1>
              <h2>
                <FormattedMessage
                  id = "title2"
                  defaultMessage={title2}
                />
              </h2>
            </div>
          </div> */}
          <a 
            href={`${langContext.locale === 'ru' ? "https://edu.gov.ru" : "https://edu.gov.ru/en"}`} 
            className="banner__logo-minpros banner__right logo-minpros">
              &nbsp;
          </a>
        </div>
      </Container>
    </header>
  )
}
        