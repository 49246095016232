import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Footer.scss'
import { FormattedMessage } from 'react-intl'

export default function Footer({ data, ...props }) {
  const { title, contacts } = data

  const showContactItem = (item, index) => {
    return <Row className={`footer__row`} key={`contact_${index}`}>
      <Col lg={10} sm={6}>
        <div className="footer__contact">
          {item.title && (
            <Row className="footer__contact-row">
              <p>
                <FormattedMessage
                  id={`contact${item.id}_title`}
                  defaultMessage={item.title}
                />
              </p>
            </Row>
          )}
          {item.site && (
            <Row className="footer__contact-row">
              <p><a href={item.site}>{item.site}</a></p>
            </Row>
          )}
          {item.location && (
            <Row className="footer__contact-row">
              <i className="icon-location" />
              <p>
                <FormattedMessage
                  id={`contact${item.id}_location`}
                  defaultMessage={item.location}
                />
              </p>
            </Row>
          )}
          {item.email && (
            <Row className="footer__contact-row">
              <i className="icon-mail" />
              <p><a href={`mailto://${item.email}`}>{item.email}</a></p>
            </Row>
          )}
          {item.phone && (
            <Row className="footer__contact-row">
              <i className="icon-phone" />
              <p>{item.phone}</p>
            </Row>
          )}
        </div>
      </Col>
      <Col lg={3} sm={6} className="footer__social-container">
        <div className="footer__social">
          {item.social.map((item, index) => <a key={`soc_${index}`} href={item.href} target="noopener noreferrer"><i className={item.icon}></i></a>)}
        </div>
      </Col>
    </Row>
  }

  return (
    <footer className={`footer`}>
      <div className="footer__top">
        <Container>
          <div className="footer__header">
            <h2>
              <FormattedMessage
                id="contacts_title"
                defaultMessage={title}
              />
            </h2>
          </div>
          <div className='footer__contacts'>
            {showContactItem(contacts[0], 0)}
            <Row className={`footer__row`}>
              <Col lg={10} sm={6}>
                <div className="footer__contact">
                </div>
              </Col>
              <Col lg={3} sm={6} className="footer__social-container">
                <div className="footer__social">
                </div>
              </Col>
            </Row>
            {contacts.filter((item, index) => index > 0).map((item, index) =>
              showContactItem(item, index)
            )}
          </div>
        </Container>
      </div>
      <Container className="footer__bottom">
        <Col className="footer__copyright">
          &copy; «Центр международного сотрудничества Министерства просвещения Российской Федерации», {new Date().getFullYear()} г..
        </Col>
      </Container>
    </footer>
  )
}
