import './App.scss';
import LandingPage from './components/pages/LandingPage';
import PageUnderwayWorks from './components/pages/PageUnderwayWorks';
import './assets/stylesheets/flag-icon.scss'


function App() {
  return (
    <div className="App">
      {/* <PageUnderwayWorks /> */}
      <LandingPage />
    </div>
  );
}

export default App;
