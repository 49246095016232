import React, { useContext } from 'react';
import './Header.scss';
import { Container } from 'react-bootstrap';
import { Context } from "../containers/LanguageWrapper";

export default function Header() {

  const langContext = useContext(Context);

  function flagIcon(locale) {
    switch (locale) {
      case 'ru':
        return 'flag-icon-rus'
      case 'en':
        return 'flag-icon-gbr'
      default:
        return 'flag-icon-gbr';
    }
  }

  return (
    <div>
      <Container>
        <div className="header">
          <div className={`${flagIcon(langContext.locale)} flag-icon`} />
          <select id='lang' className="header__select" value={langContext.locale} onChange={langContext.selectLanguage}>
            <option value='ru'>Русский</option>
            <option value='en'>English</option>
          </select>
        </div>

      </Container>
    </div>
  )
}
